<template>
    <div class="user_defined_banner">
        <article style="background:repeating-linear-gradient(to right,#202E43,#254E5F,#276268,#0C8A8D)">
            <div class="container-1200 mh111">
                <div class="layui-fluid" style="height: 40px;">
                    <div class="layui-row" style="color: white;line-height: 45px;">
                        <div class="layui-col-md6 page_tips">
                            Welcome to Article Citation Contribution Indicator!
                        </div>
                        <div class="layui-col-md6 layui-col-right float-hand" v-show="!isLogin">
                            <a href="/login" style="color: white">Log in</a>
                        </div>
                        <div class="layui-col-md6 layui-col-right" v-if="isLogin">
                            <p><span class="username" style="margin-right: 30px">
                                    {{ $store.getters.getUserInfo.userName }}
                                </span><span class="float-hand logout" @click="loginOut">Log out</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="header-bg">
                <div class="container-1200" style="padding-top: 40px;">
                    <div class="layui-fluid">
                        <div class="layui_box">
                            <div class="logo_image" @click="goToHome">
                                <img :src="require('@/assets/images/logo_wirte_.png')" />
                            </div>
                            <div id="warp-menu" style="margin:25px 0px 0px 333px;">
                                <MenuVue></MenuVue>
                            </div>

                            <div class="menu_btns" @click="() => drawer = true">
                                <img src="../../assets/images/daohang.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="banner_content">
                    <DefaultContentVue></DefaultContentVue>
                </div>

                <img src="../../assets/images/mask_group_.png" alt="" class="mask_image">
            </section>


            <el-drawer title="" :visible.sync="drawer" :direction="'ttb'" :size="'100%'">
                <template #title>
                    <div class="menu_logo" @click="goToHome">
                        <img :src="require('@/assets/images/logo_white.jpg')" />
                    </div>
                </template>
                <MenuList @closeDrawer="closeDrawer"></MenuList>
            </el-drawer>

        </article>
    </div>
</template>

<script>
/** 
 * @description 自定义导航及 banner 图 ，无特定需求情况下使用 meetingHead.vue ，需要定制使用此组件。
 *  组件 props 均无定义，后续可根据需要添加
 *  组件插槽均无添加 后续可完善此组件
 *  组件模块未进行抽离 后续可进行抽离
 *  等.... 
 * @author fhl
 * @date 2024.10.15
 * @example <user-defined-banner></user-defined-banner>
 */

import { props } from "./data"
import $http from "@/request/http";
import DefaultContentVue from "./defaultContent.vue";
import MenuVue from "../menu.vue";
import MenuList from "../menuList.vue";

export default {
    props: props,
    components: {
        DefaultContentVue,
        MenuVue,
        MenuList
    },
    data() {
        return {
            isLogin: this.$store.getters.getIsLogin,
            userInfo: this.$store.getters.getTokenInfo,
            routerR: true,
            drawer: false
        };
    },
    watch: {
        //监听登录状态
        "$store.getters.getIsLogin": {
            handler(isLogin) {
                this.isLogin = isLogin
            },
            immediate: true,
        },
    },
    methods: {
        loginOut() {
            $http.loginTrue("logout")
                .then(() => {
                    this.$store.commit('setTokenInfo', "")
                    this.$store.commit('setUserInfo', "")
                    this.$store.commit('setIsLogin', "")

                    this.$message.success({
                        showClose: true,
                        duration: 1500,
                        message: "success logout",
                    });

                })
                .catch(function () { });
        },
        goToHome() {
            this.closeDrawer()
            this.$router.push('/index');
        },
        closeDrawer() {
            this.drawer = false
        }
    },
}
</script>

<style lang="scss">
.user_defined_banner {
    .mh111 {
        .layui-fluid {
            padding: 0;

            .layui-row {
                display: flex;
                justify-content: space-between;

                &::after {
                    display: none;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    .el-menu-demo {
        display: flex;
        align-items: center;

        li,
        .el-submenu__title {
            font-size: 18px;
        }

        .float-hand {
            .el-submenu__title {
                border-bottom-color: rgb(255, 146, 0) !important;

                .about_text {
                    color: rgb(255, 146, 0) !important;
                }
            }
        }

        .apply_btn {
            margin-left: 20px;
        }
    }

    .header-bg {
        position: relative;
        height: 496px;

        .logo_image {
            &:hover {
                cursor: pointer;
            }
        }

        .layui_box {
            display: flex;
            align-items: center;
            padding-right: 40px;
        }

        .banner_content {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .mask_image {
            position: absolute;
            right: 20%;
            bottom: -142px;
        }
    }
}

.menu_btns {
    display: none;

    img {
        width: 30px;
    }
}

.menu_logo {
    img {
        width: 160px;
    }
}

@media (max-width: 768px) {
    .header-bg {
        height: auto !important;

        .layui_box {
            justify-content: space-between;
            padding-right: 0 !important;
        }
    }

    .banner_content {
        padding: 50px 0;
    }

    .mask_image {
        display: none;
    }

    #warp-menu {
        display: none;
    }

    .logo_image {
        img {
            width: 70%;
        }
    }

    .menu_btns {
        display: block;
    }

    .logo_sdf {
        display: flex;
        justify-content: space-between;
    }

    .page_tips{
        font-size: 12px;
    }
}
</style>