<template>
    <div class="page_anli">
        <WidgetLayout>
            <template #content>
                <div class="anli_new_cases">
                    <pubCarousel></pubCarousel>
                </div>
                <div class="customer_box">
                    <CustomerList></CustomerList>
                </div>

                <div class="anli_evaluate">
                    <EvaluateIssue :isShowEvaluate="false"></EvaluateIssue>
                </div>
            </template>
        </WidgetLayout>
    </div>
</template>

<script>
import WidgetLayout from "./layout.vue"
import UserDefinedBanner from '@/components/userDefinedBanner/index.vue'
import PageSearch from "@/components/pageSearch/index.vue"
import NewCases from "@/components/newCases/index.vue"
import CustomerList from "@/components/customerList/index.vue"
import EvaluateIssue from "@/components/evaluateIssue/index.vue"
import pubCarousel from "@/components/pubCarousel/index.vue"

export default {
    name: "newcase",
    components: {
        WidgetLayout,
        UserDefinedBanner,
        PageSearch,
        pubCarousel,
        CustomerList,
        EvaluateIssue
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
.page_anli {
    .anli_new_cases,
    .customer_box {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .anli_new_cases {
        margin-top: 100px;
    }

    .customer_box {
        margin-top: 100px;
    }

    .anli_evaluate {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .page_anli {
        .anli_new_cases {
            margin-top: 20px;
        }
    }
}
</style>