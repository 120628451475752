<template>
    <div class="customer_list">
        <div class="customer_titie" v-if="isShowTitle">
            OUR CUSTOMERS
        </div>
        <div class="customer_content">
            <div class="customer_statistics" v-if="isShowStatistics">
                <div class="statistics_box">
                    * There have <span class="storng_text">{{ statistics.installedCount }}</span> Publisher are already
                    using
                    Contrimetric·Plugin...
                </div>
                <div class="statistics_box">
                    * There have <span class="storng_text">{{ statistics.noInstallCount }}</span> Journals have
                    completed
                    plugin code
                    production...
                </div>
            </div>

            <div class="customer_image_list customer_image_list_dx" v-if="isShowUAImage">
                <div :class="['list_item list_item_dx', `list_item_dx-${index}`]" v-for="(item, index) in dxData"
                    :key="index">
                    <img :src="item.image" alt="" @click="goToBlank(item.link)">
                    <span v-if="item.text != ''" class="list_item_text">{{ item.text }}</span>
                </div>
            </div>

            <div class="customer_image_list" v-if="isShowImage">
                <div id="list_item" :class="['list_item', `item-${index}`]" v-for="(item, index) in data" :key="index">
                    <img :src="item.image" alt="" @click="goToBlank(item.link)">
                    <span v-if="item.text != ''" class="list_item_text">{{ item.text }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getUseJournalCount } from '@/api/widget';
import { data, dxData } from './data';

export default {
    props: {
        isShowTitle: {
            type: Boolean,
            default: true
        },
        isShowStatistics: {
            type: Boolean,
            default: true
        },
        isShowImage: {
            type: Boolean,
            default: true
        },
        isShowUAImage: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            data,
            dxData,
            statistics: {
                noInstallCount: 0,
                installedCount: 0
            }
        }
    },
    methods: {
        goToBlank(link) {
            window.open(link, '_blank')
        },
        async getUseJournalCountFun() {
            await getUseJournalCount().then(res => {
                console.log({ ...res.data });
                this.statistics = { ...res.data }
            })
        }
    },
    async mounted() {
        if (this.isShowStatistics) await this.getUseJournalCountFun()
    },
}
</script>

<style lang="scss">
@import "../../assets/css/minix.scss";

.customer_list {
    @include responsive-width(1200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .customer_titie {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .customer_content {
        width: 100%;

        .customer_statistics {
            position: relative;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-around;
            font-size: 16px;
            width: 100%;
            padding: 20px 100px;
            background-color: #f4e5e5;
            box-sizing: border-box;
            overflow: hidden;

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                width: 35px;
                height: 100%;
                background-color: white;
            }

            &::before {
                left: 0;
                transform: skew(336deg);
                transform-origin: top right;
            }

            &::after {
                right: -28px;
                transform: skew(340deg);
                transform-origin: top left;
            }

            .statistics_box {
                .storng_text {
                    color: #8e0000;
                    font-size: 30px;
                    font-family: fantasy;
                }
            }
        }

        .customer_image_list_dx {
            .list_item {
                margin: 30px 20px !important;
            }
        }

        .customer_image_list {
            display: flex;
            flex-wrap: wrap;

            .list_item_dx {
                width: 360px;
                height: 150px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }

            .list_item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 10px 20px;
                transition: all .3s;

                width: 360px;
                height: 150px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }

                .list_item_text {
                    margin-top: 10px;
                }

                &:hover {
                    cursor: pointer;
                    filter: opacity(0.7);
                }

                // &.item-3 {
                //     img {
                //         width: 300px;
                //     }
                // }

                // &.item-4 {
                //     img {
                //         width: 300px;
                //     }
                // }

                // &.item-5 {
                //     img {
                //         width: 475px;
                //     }
                // }

                // &.item-1 {
                //     img {
                //         width: 200px;
                //     }
                // }

                // &.item-2 {
                //     img {
                //         width: 648px;
                //     }
                // }

                // &.item-6 {
                //     img {
                //         width: 120px;
                //     }
                // }


            }
        }
    }
}

@media (max-width: 768px) {
    .customer_list {
        padding: 0;

        .customer_content {
            .customer_statistics {
                position: static;
                flex-direction: column;
                padding: 20px;

                &::before,
                &::after {
                    content: "";
                    display: none;
                }

                .statistics_box {
                    text-align: justify;

                    &:nth-child(1) {
                        margin-bottom: 20px;
                    }
                }
            }

            .customer_image_list {
                justify-content: center;

                #list_item {
                    margin: 10px;
                    flex: 0 0 44%;
                    height: 100px;

                    img {
                        width: 100%;
                    }

                    .list_item_text {
                        font-size: 12px;
                    }

                    &.item-6 {
                        img {
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
}
</style>