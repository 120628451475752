<template>
    <div class="banner_default_box">
        <div class="box_title">{{ defaultContentTitle }}</div>
        <div class="box_content">
            <div v-for="(item, index) in defaultContentList" :key="index" class="content_item">{{ item }}</div>
        </div>
    </div>
</template>

<script>
import { defaultContentTitle, defaultContentList } from "./data"

export default {
    data() {
        return {
            defaultContentTitle,
            defaultContentList
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/minix.scss";

.banner_default_box {
    @include responsive-width(1200px);
    padding-left: 50px;
    padding-top: 100px;

    .box_title {
        font-size: 30px;
        color: #e1f8fa;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .box_content {
        .content_item {
            position: relative;
            font-size: 16px;
            color: #ffffff;
            padding-left: 26px;
            margin-bottom: 14px;

            &::before {
                content: "•";
                font-size: 40px;
                color: #ff9200;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

@media (max-width: 768px) {
    .banner_default_box .box_title {
        font-size: 24px;
    }
}
</style>